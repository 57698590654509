import { MenuItemLocation, PlausibleEvent } from 'enums/plausible'
import { FC, useCallback, useEffect } from 'react'
import menuLinks, { MenuLink } from 'static/menuLinks'
import usePlausible from 'utils/hooks/usePlausible'

import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import SocialLinks from 'components/SocialLinks/SocialLinks'
import Typography from 'components/Typography'

import styles from './SiderNavigation.module.scss'

interface ISiderNav {
  visibleBlock: string
  referrer: string
}

const NavBlock: FC<MenuLink & { referrer: string }> = ({ title, sublinks, referrer }) => {
  const { t } = useTranslation(['shared'])
  const setEvent = usePlausible()
  const onClick = useCallback((link: MenuLink) => {
    setEvent(PlausibleEvent.ClickedMenuItem, {
      name: link.title,
      url: link.href,
      referrer,
      location: MenuItemLocation.Header,
    })
  }, [])

  return (
    <div className={styles.navBlockItem}>
      <Typography variant="h3" className={styles.navBlockItemTitle}>
        {t(`${title}`)}
      </Typography>
      <div className={styles.navBlockItemSublinks}>
        {sublinks.map((subLink) => {
          if (subLink.type === 'external') {
            return (
              <a
                key={subLink.title}
                href={subLink.href}
                target={subLink.target}
                className={styles.navBlockItemSublink}
                onClick={() => onClick(subLink)}
              >
                <Typography variant="pAccent">{t(`${subLink.title}`)}</Typography>
              </a>
            )
          }
          return (
            <Link
              key={subLink.title}
              href={subLink.href}
              target={subLink.target}
              className={styles.navBlockItemSublink}
              onClick={() => onClick(subLink)}
            >
              <Typography variant="pAccent">{t(`${subLink.title}`)}</Typography>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const SiderNavigation: FC<ISiderNav> = ({ referrer }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.navBlockWrapper}>
        {menuLinks.map((mainLink) => {
          return <NavBlock key={mainLink.title} referrer={referrer} {...mainLink} />
        })}
      </div>

      <SocialLinks referrer={referrer} classNames={styles.social} />
    </div>
  )
}

export default SiderNavigation
