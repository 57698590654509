import cn from 'classnames'
import { PlausibleEvent, SocialLocation } from 'enums/plausible'
import Cookies from 'js-cookie'
import { FC, useCallback, useMemo } from 'react'
import socialNetworks from 'static/socialNetworks'
import usePlausible from 'utils/hooks/usePlausible'

import Image from 'next/image'

import styles from './SocialLinks.module.scss'

interface ISocialLinks {
  referrer: string
  fromContats?: boolean
  classNames?: string
  pageName?: string
}

const SocialLinks: FC<ISocialLinks> = ({ referrer, fromContats, classNames, pageName }) => {
  const setEvent = usePlausible()
  const onClick = useCallback((link: { name: string; href: string }) => {
    setEvent(PlausibleEvent.ClickedSocials, {
      name: link.name,
      url: link.href,
      referrer,
      location: fromContats ? SocialLocation.Contacts : SocialLocation.Footer,
    })
  }, [])

  const hostname = Cookies.get('hostname')

  const mappedSocialNetworks = useMemo(
    () =>
      socialNetworks.map((sn) => {
        if (sn.name === 'telegram' && hostname === 'binany24.com') {
          return {
            ...sn,
            href: 'https://t.me/binany_bangladesh',
          }
        }
        return sn
      }),
    [hostname],
  )

  return (
    <div className={cn(styles.social, classNames)}>
      {mappedSocialNetworks.map((link, idx) => (
        <a
          href={link.href}
          rel="noreferrer"
          target="_blank"
          onClick={() => onClick(link)}
          key={idx}
          className={styles.social_icon}
          id={`${pageName}-${link.name}-link`}
        >
          <Image fill src={link.imgSrc} alt="social network" loading="lazy" />
        </a>
      ))}
    </div>
  )
}

export default SocialLinks
