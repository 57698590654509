import classNames from 'classnames'
import { forwardRef, PropsWithChildren } from 'react'
import Image from 'next/image'
import Link, { LinkProps } from 'next/link'
import styles from './MenuButton.module.scss'

export interface MenuButtonProps extends LinkProps {
  variant?: 'default' | 'circuit'
  className?: string
  id?: string
  icon?: string
}

const MenuButton = forwardRef<HTMLAnchorElement, PropsWithChildren<MenuButtonProps>>(
  (props, ref) => {
    const { children, variant = 'default', className, id, icon, ...rest } = props

    const buttonClassName = classNames(className, styles.button, styles[variant])

    return (
      <Link className={buttonClassName} ref={ref} id={id} {...rest}>
        <span className={styles.text}>{children}</span>

        {variant === 'circuit' && (
          <Image className={styles.icon} src={icon} priority width={24} height={24} alt="login" />
        )}
      </Link>
    )
  },
)

MenuButton.displayName = 'MenuButton'

export default MenuButton
