import cn from 'classnames'
import { FC, ReactNode, Ref } from 'react'

import styles from './Section.module.scss'

interface IProps {
  backgroundClass?: string
  contentClassName?: string
  withTopPadding?: boolean
  withBottomPadding?: boolean
  withHorizontalPadding?: boolean
  centered?: boolean
  forwardRef?: Ref<HTMLDivElement>
  children?: ReactNode
}

const Section: FC<IProps> = ({
  backgroundClass,
  contentClassName,
  withTopPadding = true,
  withBottomPadding = true,
  withHorizontalPadding = true,
  centered = true,
  children,
  forwardRef,
}) => {
  return (
    <div
      ref={forwardRef}
      className={cn(
        styles.Section,
        {
          [styles.withTopPadding]: withTopPadding,
          [styles.withBottomPadding]: withBottomPadding,
          [styles.withHorizontalPadding]: withHorizontalPadding,
          [styles.centered]: centered,
        },
        backgroundClass,
      )}
    >
      <div className={cn(styles.Content, contentClassName)}>{children}</div>
    </div>
  )
}

export default Section
