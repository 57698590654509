import { FC, ReactNode } from 'react'
import cn from 'classnames'

import Menu from 'components/Menu/Menu'
import Footer from 'sections/Footer/Footer'

import styles from './MainLayout.module.scss'

interface IProps {
  authLayout?: boolean
  children?: ReactNode
  referrer?: string
  contentClassName?: string
}
const MainLayout: FC<IProps> = ({ children, authLayout, referrer, contentClassName }) => (
  <>
    <Menu referrer={referrer} />
    <div className={cn(styles.Content, contentClassName)}>{children}</div>
    <Footer referrer={referrer} authLayout={authLayout} />
  </>
)

export default MainLayout
