import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

export interface ITypographyProps {
  className?: string
  title?: string
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'pAccent' | 'pMain' | 'spanNumber' | 'spanBtn'
  style?: React.CSSProperties
  component?: 'div' | 'span' | 'p'
  itemProp?: string
}

const Typography = (props: PropsWithChildren<ITypographyProps>) => {
  const { className, children, variant = 'pMain', component, ...rest } = props

  const Tag = component || (variant.includes('h') ? (variant as 'h1' | 'h2' | 'h3') : 'p')

  return (
    <Tag className={classNames(className, styles.typography, styles[variant])} {...rest}>
      {children}
    </Tag>
  )
}

export default Typography
