export interface ISLink {
  imgSrc: string
  href: string
  width: number
  height: number
  name: string
  transform?: string
}

const socialNetworks: ISLink[] = [
  {
    imgSrc: '/images/social/telegram.svg',
    width: 24,
    height: 24,
    name: 'telegram',
    href: 'https://t.me/binany_english',
    transform: 'translateX(-1px)',
  },
  {
    imgSrc: '/images/social/twitter.svg',
    width: 30,
    height: 30,
    name: 'twitter',
    href: 'https://twitter.com/BinanyTrade',
  },
  {
    imgSrc: '/images/social/instagram.svg',
    width: 30,
    height: 30,
    name: 'instagram',
    href: 'https://www.instagram.com/binanyfinance/',
  },
  {
    imgSrc: '/images/social/facebook.svg',
    width: 30,
    height: 30,
    name: 'facebook',
    href: 'https://www.facebook.com/binanytrade/',
  },
  {
    imgSrc: '/images/social/youtube.svg',
    width: 30,
    height: 30,
    name: 'youtube',
    href: 'https://www.youtube.com/channel/UCMUUUIOd3wZg_GLDC70K8YQ/',
  },
]

export default socialNetworks
