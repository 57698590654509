import cn from 'classnames'
import { FC, ReactNode } from 'react'

import styles from './Sider.module.scss'

interface ISider {
  position?: 'left' | 'right'
  children?: ReactNode
}

const Sider: FC<ISider> = ({ position = 'left', children }) => {
  return (
    <div className={cn(styles.Sider, styles[position])}>
      <div className={styles.Content}>{children}</div>
    </div>
  )
}

export default Sider
