import cn from 'classnames'
import { PlausibleEvent } from 'enums/plausible'
import { useCallback, useEffect, useRef, useState } from 'react'
import languages, { whiteList } from 'static/languages'
import usePlausible from 'utils/hooks/usePlausible'
import { useInfoProviderContext } from 'utils/providers/InfoProvider/InfoProviderContext'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'

import styles from './LanguagePicker.module.scss'

import ArrowDown from 'icons/arrow-down.svg'

interface LanguagePickerProps {
  referrer?: string
  position?: string
}

const LanguagePicker = ({ referrer, position }: LanguagePickerProps) => {
  const { i18n } = useTranslation()
  const router = useRouter()
  const setEvent = usePlausible()
  const { pathname, asPath, query } = router

  const info = useInfoProviderContext()

  const geoCode = info?.code

  const changeLanguage = useCallback(
    (locale) => {
      const routerOptions = {
        pathname,
        query,
      }
      setEvent(PlausibleEvent.ClickedLanguagesLang, {
        step: 2,
        referrer,
        language: i18n.language,
        geo: geoCode,
        location: `land`,
        selectedLanguage: locale,
      })
      localStorage.setItem(`selectedLang`, locale)
      router.push(routerOptions, asPath, {
        locale,
      })
    },
    [pathname, asPath, query, geoCode],
  )

  const [isLangDropdownOpened, toggleLangDropdown] = useState(false)
  const toggleLanguageModal = useCallback(() => {
    setEvent(PlausibleEvent.ClickedLanguagesSelection, {
      step: 1,
      referrer,
      language: i18n.language,
      geo: geoCode,
      location: `land`,
    })

    toggleLangDropdown((oldState) => !oldState)
  }, [toggleLangDropdown, geoCode, i18n.language])

  const langDropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        langDropdownRef.current &&
        !langDropdownRef.current?.contains(event.target as HTMLDivElement)
      ) {
        toggleLangDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [langDropdownRef])

  useEffect(() => {
    const dir = i18n.language === 'ar' ? 'rtl' : 'ltr'
    document.querySelector('body').setAttribute('dir', dir)
  }, [i18n.language])

  const [language, setLanguage] = useState(() => {
    return languages.find((lang) => lang.locale === 'en')
  })

  useEffect(() => {
    if (i18n.language) {
      const lg = languages.find((lang) => lang.locale === i18n.language)
      setLanguage(lg)
    }
  }, [i18n, i18n.language])

  useEffect(() => {
    if (!whiteList.includes(i18n.language)) {
      changeLanguage(whiteList[0])
    }
  }, [])

  return (
    <div ref={langDropdownRef} className={styles.dropdownContainer}>
      <button
        onClick={toggleLanguageModal}
        className={cn(styles.LangButton)}
        id="common-header-language-select"
      >
        <div className={styles.flagImageWrapper}>
          <Image priority fill src={language.iconRect} alt={language.name} />
        </div>
        <ArrowDown />
      </button>

      {isLangDropdownOpened && (
        <div
          className={cn(styles.dropdownContent, {
            [styles.opened]: isLangDropdownOpened,
            [styles.left]: position === 'left',
          })}
        >
          <div className={styles.picker}>
            {whiteList.map((locale, idx) => {
              const lang = languages.find((lg) => lg.locale === locale)

              return (
                <div
                  key={idx}
                  onClick={() => changeLanguage(lang.locale)}
                  className={cn(styles.langItem, {
                    [styles.active]: lang.locale === i18n.language,
                  })}
                >
                  <Image
                    loading="lazy"
                    width={24}
                    height={16}
                    src={lang.iconRect}
                    alt={lang.name}
                  />
                  <a
                    id={`common-header-${lang.name}-option`}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleLangDropdown(false)
                    }}
                    href={`${lang.href}${asPath.length === 1 ? '' : asPath}`}
                  >
                    {lang.originalName}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default LanguagePicker
