import cn from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import menuLinks from 'static/menuLinks'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'

import LanguagePicker from 'components/LanguagePicker/LanguagePicker'
import MenuButton from 'components/MenuButton'
import Sider from 'components/Sider/Sider'
import SiderNavigation from 'components/SiderNavigation/SiderNavigation'

import styles from './Menu.module.scss'

import ArrowDown from 'icons/arrow-down.svg'
import TimesRound from 'icons/times-round.svg'

const Burger = (props: { classNames?: string }) => {
  const { classNames } = props

  return (
    <div className={cn(styles.BurgerIcon, classNames)}>
      <div className={styles.Slice} />
      <div className={styles.Slice} />
      <div className={styles.SliceShort} />
    </div>
  )
}

const Menu = ({ referrer }) => {
  const { t } = useTranslation(['shared', 'main'])

  const [visibleModal, setVisibleModal] = useState<'nav' | 'lang' | null>(null)
  const [navigationContext, setNavigationContext] = useState<string | null>(null)

  const toggleNavigationModal = useCallback(
    (ctx: string | null = null) => {
      setVisibleModal((oldState) => {
        if (ctx !== navigationContext) return 'nav'
        return oldState ? null : 'nav'
      })
      setNavigationContext(ctx)
    },
    [setNavigationContext, setVisibleModal, navigationContext],
  )

  return (
    <div className={cn(styles.MenuWrapper, { [styles.withModal]: visibleModal })}>
      <div className={styles.Modal}>
        {visibleModal === 'nav' && (
          <Sider position="left">
            <SiderNavigation referrer={referrer} visibleBlock={navigationContext} />
          </Sider>
        )}
      </div>
      <div className={styles.Menu}>
        <button
          onClick={() => toggleNavigationModal()}
          className={styles.BurgerButton}
          aria-label="Menu"
        >
          <Burger classNames={cn(styles.burger, { [styles.opened]: !visibleModal })} />
          <TimesRound className={cn(styles.close, { [styles.opened]: visibleModal })} />
        </button>
        <div className={styles.Logo}>
          <Link className={styles.LogoLink} href="/" id="common-header-logo-link">
            <Image src="/images/logoWhite.svg" fill alt="Binany" priority />
          </Link>
        </div>
        <div className={styles.Links}>
          {menuLinks.map((link, idX) => {
            if (idX === 2) return null

            const [isDropdownOpened, toggleDropdownOpened] = useState(false)

            const ref = useRef<HTMLDivElement | null>(null)

            useEffect(() => {
              const handleClickOutside = (event: MouseEvent) => {
                if (ref.current && !ref.current?.contains(event.target as HTMLDivElement)) {
                  toggleDropdownOpened(false)
                }
              }

              document.addEventListener('mousedown', handleClickOutside)

              return () => {
                document.removeEventListener('mousedown', handleClickOutside)
              }
            }, [ref])

            return (
              <div ref={ref} className={styles.dropdownContainer} key={`${idX}${link.title}`}>
                <button
                  onClick={() => toggleDropdownOpened((prevState) => !prevState)}
                  className={styles.menuButton}
                  id={`common-header-${link.name}-select`}
                >
                  {t(link.title)} <ArrowDown />
                </button>

                {isDropdownOpened && (
                  <div
                    className={cn(styles.dropdownContent, {
                      [styles.opened]: isDropdownOpened,
                    })}
                  >
                    {link?.sublinks.map((sublink, idY) => (
                      <Link
                        className={styles.sublink}
                        key={`${idY}${sublink.title}`}
                        href={sublink.href}
                        id={`common-header-${sublink.name}-option`}
                      >
                        {t(sublink.title)}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )
          })}

          <Link
            className={styles.menuButton}
            href="https://binanypartner.com/"
            target="_blank"
            type="external"
            id="common-header-partner-link"
          >
            {t(`shared:navigationProgram`)}
          </Link>
        </div>
        <div className={styles.actions}>
          <LanguagePicker referrer={referrer} />

          <MenuButton
            href="/auth/signin"
            variant="circuit"
            icon="/images/util/login.svg"
            id="common-header-login-button"
          >
            {t(`shared:navigationLogin`)}
          </MenuButton>

          <MenuButton
            href="/auth/signup?from=header"
            as="/auth/signup"
            id="common-header-signup-button"
          >
            {t(`shared:navigationSignUp`)}
          </MenuButton>
        </div>
      </div>
    </div>
  )
}

export default Menu
