import cn from 'classnames'
import { MenuItemLocation, PlausibleEvent } from 'enums/plausible'
import Cookies from 'js-cookie'
import { FC, useCallback, useEffect, useState } from 'react'
import menuLinks, { MenuLink } from 'static/menuLinks'
import usePlausible from 'utils/hooks/usePlausible'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'

import Section from 'components/Section/Section'
import SocialLinks from 'components/SocialLinks/SocialLinks'
import Typography from 'components/Typography'

import styles from './Footer.module.scss'

const NavLinks = ({ referrer }) => {
  const { t } = useTranslation(['shared'])
  const setEvent = usePlausible()
  const onClick = useCallback((link: MenuLink) => {
    setEvent(PlausibleEvent.ClickedMenuItem, {
      name: link.title,
      url: link.href,
      referrer,
      location: MenuItemLocation.Footer,
    })
  }, [])
  return (
    <div className={styles.navlinks}>
      {menuLinks.map((nv, idx) => (
        <div className={styles.navlink} key={idx}>
          <Typography variant="h4" className={styles.navlinkTitle}>
            {t(nv.title)}
          </Typography>

          <div className={styles.sublinks}>
            {nv.sublinks.map((sub, idx2) => {
              if (sub.type === 'external') {
                return (
                  <a
                    key={idx2}
                    href={sub.href}
                    onClick={() => onClick(sub)}
                    target={sub.target}
                    id={`common-footer-${sub.name}-link`}
                  >
                    <Typography variant="pMain">{t(sub.title)}</Typography>
                  </a>
                )
              }
              return (
                <Link
                  key={idx2}
                  href={sub.href}
                  onClick={() => onClick(sub)}
                  target={sub.target}
                  id={`common-footer-${sub.name}-link`}
                >
                  <Typography variant="pMain">{t(sub.title)}</Typography>
                </Link>
              )
            })}
          </div>
        </div>
      ))}

      <div className={cn(styles.navlink, styles.disclaimer)}>
        <Typography variant="h4" className={styles.navlinkTitle}>
          {t('shared:footerRisksTitle')}
        </Typography>
        <Typography variant="pMain" className={styles.sublinks}>
          {t('shared:footerRisksText')}
        </Typography>
      </div>
    </div>
  )
}

interface IProps {
  // TODO Remove this unused prop
  // eslint-disable-next-line react/no-unused-prop-types
  authLayout?: boolean
  referrer: string
}
const Footer: FC<IProps> = ({ referrer }) => {
  const { t } = useTranslation(['shared'])
  const [linkText, setLinkText] = useState('')

  const hostname = Cookies.get('hostname')

  useEffect(() => {
    switch (hostname) {
      case 'binany.uz':
        setLinkText('binany1.uz')
        break
      case 'binany24.com':
        setLinkText('binanybd.com')
        break
      default:
        setLinkText('binany1.com')
        break
    }
  }, [hostname])

  return (
    <footer className={styles.footer}>
      <Section withTopPadding={false} backgroundClass={styles.footerSection}>
        <div className={styles.grid}>
          <div className={styles.logoAndSocials}>
            <div className={styles.logo}>
              <Image fill src="/images/logoWhite.svg" alt="logo" loading="lazy" />
            </div>
            <SocialLinks referrer={referrer} classNames={styles.social} pageName="common-footer" />
          </div>
          <div className={styles.footerTop}>
            <NavLinks referrer={referrer} />
          </div>
          <div className={styles.footerBottom}>
            <div className={styles.copyright}>
              © Binany, {new Date().getFullYear()}. {t('main:footerRightsReserved')}. Crypex IT ltd{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://${linkText}/`}
                id="common-footer-copyright-link"
              >
                Binany
              </a>
            </div>
          </div>
        </div>
      </Section>
    </footer>
  )
}
export default Footer
